import {
  IconBed,
  IconHeart,
  IconHeartOutline,
  IconMapMarkerOutline,
  IconPhoto,
  IconShower,
  IconStickerText,
  IconTagOutline,
  IconVectorSquareClose,
  IconVideo,
} from 'components/icons';
import { useAuthenticationContext } from 'components/providers/authentication-provider';
import ModalWrapper from 'components/shared/modal-wrapper';
import {
  FileWithFullUrls,
  GET_REAL_ESTATE_DETAILS,
  GetRealEstateDetailsData,
  GetRealEstateDetailsVariables,
  RealEstate,
  YoutubeFile,
} from 'graphql/main/queries';
import { LeadRealEstateStatus } from 'graphql/main/queries/get-list-lead-real-estates';
import { useFlexLazyQuery, useModal, useTranslation } from 'hooks';
import {
  filter,
  gt,
  includes,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  map,
  reduce,
  toNumber,
  upperFirst,
} from 'lodash';
import moment from 'moment-timezone';
import Link from 'next/link';
import { Fragment, MouseEvent, useState } from 'react';
import { convertNumberToVietnameseCurrencyShortString } from 'utils';
import SaveRealEstateModal from '../save-real-estate-modal';

interface Props {
  type: 'normal' | 'vip' | 'authorized' | 'special' | 'deposit' | 'foreclosure' | 'lead';
  realEstate?: RealEstate;
}

const RealEstateVerticalCard = ({ type, realEstate }: Props) => {
  const translation = useTranslation();
  const [privateRealEstate, setPrivateRealEstate] = useState(realEstate);
  const { currentUser } = useAuthenticationContext();
  const [getRealEstateDetails] = useFlexLazyQuery<
    GetRealEstateDetailsData,
    GetRealEstateDetailsVariables
  >('main', GET_REAL_ESTATE_DETAILS);
  const mediaCountObjects = [
    {
      types: ['video', 'youtube'],
      icon: <IconVideo className='h-[16px] w-[16px]' />,
      count: 0,
    },
    {
      types: ['image'],
      icon: <IconPhoto className='h-[16px] w-[16px]' />,
      count: 0,
    },
  ];
  const propertyObjects = [
    {
      icon: <IconVectorSquareClose className='h-[20px] w-[20px] text-text2' />,
      content: privateRealEstate?.area
        ? `${privateRealEstate?.area.toLocaleString()} m\u00B2`
        : '-',
    },
    {
      icon: <IconShower className='h-[20px] w-[20px] text-text2' />,
      content: privateRealEstate?.toiletCount
        ? privateRealEstate?.toiletCount.toLocaleString()
        : '-',
    },
    {
      icon: <IconBed className='h-[20px] w-[20px] text-text2' />,
      content: privateRealEstate?.bedroomCount
        ? privateRealEstate?.bedroomCount.toLocaleString()
        : '-',
    },
  ];
  const { showing: showingSavePostModal, toggle: toggleSavePostModal } = useModal();
  const images = filter(privateRealEstate?.mediaUrls, (mediaUrl: FileWithFullUrls | YoutubeFile) =>
    isEqual(mediaUrl.type, 'image'),
  );

  const save = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    toggleSavePostModal();
  };
  const completeSaving = async () => {
    const { data } = await getRealEstateDetails({
      variables: {
        id: realEstate?.id,
      },
    });
    const { getRealEstateDetails: _realEstate } = data ?? {};
    setPrivateRealEstate(_realEstate);
  };

  return (
    <Fragment>
      <Link
        href={!privateRealEstate?.fullSlug ? '#' : `/${privateRealEstate?.fullSlug}`}
        className='relative'
      >
        <div className='group flex flex-col overflow-hidden rounded-[12px] border border-stroke transition-all duration-[500ms] ease-in-out hover:shadow-6'>
          <div className='relative'>
            <div className='h-[146px] overflow-hidden'>
              <img
                src={(images?.[0] as FileWithFullUrls)?.originalUrl ?? '/images/error-image.svg'}
                alt={privateRealEstate?.postTitle}
                loading='lazy'
                className='h-full w-full object-cover transition-all duration-[500ms] ease-in-out group-hover:scale-[1.1]'
              />
            </div>
            <div className='absolute left-[10px] top-[10px] flex space-x-[8px]'>
              {!isNil(privateRealEstate?.isForSell) && (
                <span
                  className={`rounded-[4px] px-[8px] py-[4px] text-[11px] font-[500] uppercase leading-[14px] text-paper ${
                    privateRealEstate?.isForSell ? 'bg-accent-sale' : 'bg-accent-rent'
                  }`}
                >
                  {privateRealEstate?.isForSell ? 'bán' : 'thuê'}
                </span>
              )}
              {includes(['authorized', 'deposit', 'foreclosure', 'lead'], type) && (
                <span className='rounded-[4px] bg-accent-sale px-[8px] py-[4px] text-[11px] font-[500] uppercase leading-[14px] text-paper'>
                  {isEqual(type, 'authorized')
                    ? 'chính chủ'
                    : isEqual(type, 'deposit')
                    ? 'ký gửi'
                    : isEqual(type, 'lead')
                    ? 'đầu chủ'
                    : 'phát mãi'}
                </span>
              )}
              {includes(['special', 'vip'], type) && (
                <span className='rounded-[4px] bg-accent-sale px-[8px] py-[4px] text-[11px] font-[500] uppercase leading-[14px] text-paper'>
                  {isEqual(type, 'special') ? 'vip đặc biệt' : 'vip 1'}
                </span>
              )}
              {isEqual(type, 'lead') &&
                realEstate?.leadREStatus &&
                includes(
                  [
                    LeadRealEstateStatus.sold,
                    LeadRealEstateStatus.onSell,
                    LeadRealEstateStatus.inDispute,
                    LeadRealEstateStatus.locked,
                  ],
                  realEstate.leadREStatus,
                ) && (
                  <span className='rounded-[4px] bg-accent-sale px-[8px] py-[4px] text-[11px] font-[500] uppercase leading-[14px] text-paper'>
                    {translation.major.leadRealEstateStatus[realEstate.leadREStatus]}
                  </span>
                )}
            </div>
            {!isEmpty(privateRealEstate?.mediaUrls) && (
              <div className='absolute bottom-[5px] right-[6px] flex flex-col space-y-[5px]'>
                {map(
                  filter(
                    reduce(
                      privateRealEstate?.mediaUrls,
                      (accumulator, mediaUrl) =>
                        map(accumulator, (item) => {
                          if (includes(item.types, mediaUrl.type)) {
                            return { ...item, count: item.count + 1 };
                          }

                          return item;
                        }),
                      mediaCountObjects,
                    ),
                    (mediaCountObject) => gt(mediaCountObject.count, 0),
                  ),
                  (mediaCountObject, mediaCountObjectIndex) => (
                    <div
                      key={mediaCountObjectIndex}
                      className='flex items-center justify-center space-x-[4px] rounded-[4px] bg-[#000000]/[.2] px-[12px] py-[3px] text-paper'
                    >
                      <span className='text-[12px] leading-[14px]'>{mediaCountObject.count}</span>
                      {mediaCountObject.icon}
                    </div>
                  ),
                )}
              </div>
            )}
          </div>
          <div className='flex flex-col space-y-[16px] px-[16px] py-[12px]'>
            <span
              className={`line-clamp-2 min-h-[36px] font-[600] ${
                isEqual(type, 'special')
                  ? 'text-badge'
                  : isEqual(type, 'vip')
                  ? 'text-accent-sale'
                  : isEqual(type, 'authorized') ||
                    isEqual(type, 'deposit') ||
                    isEqual(type, 'foreclosure') ||
                    isEqual(type, 'lead')
                  ? 'text-primary'
                  : ''
              }`}
            >
              {upperFirst(privateRealEstate?.postTitle)}
            </span>
            <div className='flex min-h-[20px] w-full items-center space-x-[24px]'>
              {map(
                filter(propertyObjects, (propertyObject) => Boolean(propertyObject.content)),
                (propertyObject, propertyObjectIndex) => (
                  <div
                    key={propertyObjectIndex}
                    className='flex items-center space-x-[10px] overflow-hidden'
                  >
                    {propertyObject.icon}
                    <span className='truncate'>{propertyObject.content}</span>
                  </div>
                ),
              )}
            </div>
            <div className='flex min-h-[20px] w-full items-center space-x-[10px]'>
              <IconMapMarkerOutline className='h-[20px] w-[20px] text-text2' />
              <span className='truncate'>{privateRealEstate?.shortAddress}</span>
            </div>
            <div className='flex min-h-[20px] w-full items-center space-x-[24px]'>
              <div className='flex w-full items-center space-x-[6px]'>
                <IconTagOutline className='h-[20px] w-[20px] text-alert' />
                <span className='truncate text-[16px] font-[600] leading-[20px] text-alert'>
                  {isNil(privateRealEstate?.price) ||
                  !isNumber(toNumber(privateRealEstate?.price)) ||
                  !toNumber(privateRealEstate?.price)
                    ? 'Thương lượng'
                    : `${convertNumberToVietnameseCurrencyShortString(
                        toNumber(privateRealEstate?.price),
                      )}${privateRealEstate?.isForSell ? '' : '/tháng'}`}
                </span>
              </div>
              <span className='w-full truncate text-right text-[14px] leading-[16px]'>
                {!privateRealEstate?.isForSell ||
                isNil(privateRealEstate?.price) ||
                !isNumber(toNumber(privateRealEstate?.price)) ||
                !toNumber(privateRealEstate?.price) ||
                isNil(privateRealEstate?.area)
                  ? null
                  : `${convertNumberToVietnameseCurrencyShortString(
                      toNumber(privateRealEstate?.price) / toNumber(privateRealEstate?.area),
                    )}/m\u00B2`}
              </span>
            </div>
            <div className='h-[1px] w-full bg-stroke' />
            <div className='flex min-h-[20px] items-center justify-between space-x-[24px]'>
              {privateRealEstate?.createdAt && (
                <span className='w-full truncate text-[12px] leading-[15px] text-text2'>
                  {moment(privateRealEstate.createdAt).format('DD/MM/YYYY')}
                </span>
              )}
              <div className='flex w-full items-center justify-end space-x-[16px]'>
                {currentUser?.id &&
                  privateRealEstate?.creator?.id &&
                  isEqual(currentUser.id, privateRealEstate?.creator.id) &&
                  privateRealEstate?.privateNote && (
                    <IconStickerText className='h-[20px] w-[20px] text-[#F97316]' />
                  )}
                {!isNil(currentUser) && (
                  <button
                    type='button'
                    className={`flex h-[20px] w-[20px] items-center justify-center rounded-full ${
                      privateRealEstate?.isSaved
                        ? 'bg-primary'
                        : 'border border-paper bg-[#000000]/[.2]'
                    }`}
                    onClick={save}
                  >
                    {privateRealEstate?.isSaved ? (
                      <IconHeart className='h-[12px] w-[12px] text-paper' />
                    ) : (
                      <IconHeartOutline className='h-[12px] w-[12px] text-paper' />
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
      <ModalWrapper showing={showingSavePostModal}>
        <SaveRealEstateModal
          realEstate={privateRealEstate}
          onCompleteSaving={completeSaving}
          onClose={toggleSavePostModal}
        />
      </ModalWrapper>
    </Fragment>
  );
};

export default RealEstateVerticalCard;

export const RealEstateVerticalCardSkeleton = () => (
  <div className='h-[369px] rounded-[12px] bg-secondary animate-pulse' />
);
